// Vendor
import '../../vendor/vendor'

// Components
import '../../components/components'

	$(function(){
		$("img[usemap]").mapify({
			popOver: {
				content: function(zone){
					return "<h1 class='mapify-popOver-content__title'>"+zone.attr("data-title")+"</h1><h2 class='mapify-popOver-content__city'>"+zone.attr("data-city")+"</h2><p class='mapify-popOver-content__graph pt-15'><span class='mapify-popOver-content__graph-name'>график работы:</span><br>"+zone.attr("data-graffic")+"</p>";
				},
				delay: 0.7,
				margin: "5px",
				// height: "130px",
				width: "415px"
			}

		});
	});
