// Form
import './form/form'

// // Contacts
// import './contacts/contacts'

// Hamburger
import './hamburger/hamburger'
import './slider/slider'
import '../../node_modules/bootstrap/dist/js/bootstrap.min'
import '../../node_modules/bootstrap/js/dist/carousel'
